.theme-dark {
	--text: #130e01;
	--background: #fffaeb;
	--primary: #ff8400;
	--secondary: #fff5d6;
	--accent: #cf4307;
}

.theme-light {
	--text: #fef9ec;
	--background: #140f00;
	--primary: #ff8400;
	--secondary: #291f00;
	--accent: #f86c30;
  }

/* apply to all elements */
/* * {
	background-color: var(--background);
	color: var(--text);
} */

.accent {
	color: var(--accent);
}

* {
    scrollbar-width: auto;
    scrollbar-color: #420d00 #ffffff00;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 7px;
	height: 2px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #420d00;
    border-radius: 12px;
    border: 2px solid #ffffff00;
  }